import './App.css';

import { Button, Container, Header, Segment, Grid, Menu, Icon, List, Image, Card } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'

import * as bootstrap from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';

import { Parallax } from 'react-parallax';

import profile from "./images/Clare-profile.jpg";
import logo from "./logos/Cue-Accounting-Colour.png";
import bg from "./images/busy desk.jpg";
import taxagent from "./logos/tax-agent-crop.jpg";
import myob from "./logos/myob.png";
import taxnum from "./logos/tax-number.png";
import xero from "./logos/xero.png";
import ca from "./logos/CA-logo.png";

import { Component } from 'react';
import React from 'react'


class CUEMenu extends Component {
  state = {}

  handle_on_click = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state

    return <div>
      <bootstrap.Navbar collapseOnSelect expand="lg" bg="custom-transparent" variant="light">
        <bootstrap.Container>
          <bootstrap.Navbar.Brand href="/">
            <img alt="" src={logo} width="80%" className="d-inline-block align-top" />
          </bootstrap.Navbar.Brand>
          <bootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <bootstrap.Navbar.Collapse id="responsive-navbar-nav" >
            <bootstrap.Nav className="justify-end">
              <bootstrap.Nav.Link href="#services">Services</bootstrap.Nav.Link>
              <bootstrap.Nav.Link href="#about">About</bootstrap.Nav.Link>
              <bootstrap.Nav.Link href="#contact">Contact Us</bootstrap.Nav.Link>
            </bootstrap.Nav>
          </bootstrap.Navbar.Collapse>
        </bootstrap.Container>
      </bootstrap.Navbar>
    </div>
  }

}


function AboutClare() {
  return (
    <Card fluid>
      <Card.Content>
        <Image floated='right' size="small" src={profile} />
        <Card.Meta>
          <p>Hi, I'm Clare Mudron, a registered Chartered Accountant, who has worked in the accounting industry for over 20 years.</p>
          <p>I'm experienced in both commerce and industry and have held various roles covering accounting, audit and taxation.</p>
          <p>I'm here to assist businesses (SMEs) in complying with financial and tax obligations, allowing you to invest your valuable time in other areas that are important to your business.</p>
        </Card.Meta>
      </Card.Content>
    </Card>
  )
}


function Reviews() {
  return (
    <div>
      <bootstrap.Card>
        <bootstrap.Card.Body>
          <blockquote className="blockquote mb-0">
            <q>
              Kym and I were really pleased to be able to engage Clare Mudron as our accountant. Clare has taken a real interest in our businesses and demonstrated a friendly, relaxed and efficient manner since our first meeting. Since then she has provided valuable and timely service in relation to tax compliance requirements, MYOB accounts and general accounting information about various processes in what to us is an otherwise often complex world of numbers and complex equations.
              We highly recommend Clare for small business operators and anyone who finds the world of accounting and tax compliance a complex road to navigate on their own.
            </q>
            <p />
            <footer className="blockquote-footer">
              <cite title="Source Title">Ian and Kym Headley - Linkfor Building and Tasmanian Turning Supplies</cite>
            </footer>
          </blockquote>
        </bootstrap.Card.Body>
      </bootstrap.Card>
      <bootstrap.Card>
        <bootstrap.Card.Body>
          <blockquote className="blockquote mb-0">
            <q>
            We are very grateful for CUE Accounting's ongoing support in setting up our
            business and establishing systems and procedures to make our financial recording simpler and hassle free.
            Our industry continually demands high accuracy and accountability and CUE
            Accounting have managed to meet these needs.
            </q>
            <p />
            <footer className="blockquote-footer">
              <cite title="Source Title">Jens Baufeldt (Director) - X-Tremity Prosthetics and Orthotics</cite>
            </footer>
          </blockquote>
        </bootstrap.Card.Body>
      </bootstrap.Card>
      <bootstrap.Card>
        <bootstrap.Card.Body>
          <blockquote className="blockquote mb-0">
            <q>
            Just a short note of thanks for the efficient and friendly professional service
            my wife and I
            have received from <span className="text-blue-500">Cue accounting</span>.
            Your guidance and advice with regard to our Airbnb business has been invaluable.
            I sincerely appreciate your patience when it came to collecting all the data
            required in the preparation of our tax returns.
            I have the fullest confidence in being able to recommend your services to others.
            </q>
            <p />
            <footer className="blockquote-footer">
              <cite title="Source Title">Andre Louw - Sunrise Cottages (Airbnb)</cite>
            </footer>
          </blockquote>
        </bootstrap.Card.Body>
      </bootstrap.Card>
    </div>
  )
}

function ContactUs() {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Meta>
          <a href="https://www.linkedin.com/in/clare-mudron-b6aaa17b/"><img src="https://img.icons8.com/color/48/000000/linkedin-circled--v1.png" /></a>
          <a href="https://www.facebook.com/CueAccounting"><img src="https://img.icons8.com/color/48/000000/facebook-circled--v1.png" /></a>
          <a href="mailto:clare@cueaccounting.com.au?subject=Inquiry"><img src="https://img.icons8.com/color/48/000000/circled-envelope.png" />clare@cueaccounting.com.au</a>
        </Card.Meta>
        <b>Liability limited by a scheme approved under Professional Standards Legislation.</b>
      </Card.Content>
    </Card>
  )
}


function Banner() {
  return (
    <Card fluid>
  <Card.Content>
    <bootstrap.Container>
      <bootstrap.Row xs={1} sm={1} md={2}>

        <bootstrap.Col>
          <img id="ca-logo" src={ca}/>
        </bootstrap.Col>

        {/* <bootstrap.Col>
          <img id="tax-logo" src={taxnum} />
        </bootstrap.Col> */}

        <bootstrap.Col id="tax-agent-col">
          <img id="tax-agent-logo" src={taxagent}/>
        </bootstrap.Col>

      </bootstrap.Row>
    </bootstrap.Container>
  </Card.Content>
</Card>

  )
}


function Taxation() {
  return (
    <bootstrap.Card.Body>
      <List bulleted>
        <List.Item>Income Tax Returns:
          <List bulleted className="less-space">
            <List.Item>Individuals</List.Item>
            <List.Item>Companies</List.Item>
            <List.Item>Partnerships</List.Item>
            <List.Item>Trusts</List.Item>
          </List>
        </List.Item>
        <List.Item>Goods and Services Tax (GST)
          <List bulleted className="less-space">
            <List.Item>Preparation and Lodgement of Business Activity Statements (BAS)</List.Item>
          </List>
        </List.Item>
        <List.Item>Capital Gains Tax (CGT)</List.Item>
        <List.Item>Fringe Benefits Tax (FBT)</List.Item>
        <List.Item>Payroll Tax</List.Item>
      </List>
    </bootstrap.Card.Body >
  )
}


function TaxationModal() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <bootstrap.Button size="lg" variant="link" onClick={handleShow}><Icon name="calculator" />Taxation</bootstrap.Button>
      <bootstrap.Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <bootstrap.Modal.Header>
          <bootstrap.Modal.Title>Taxation</bootstrap.Modal.Title>
        </bootstrap.Modal.Header>
        <bootstrap.Modal.Body>
          <Taxation />
        </bootstrap.Modal.Body>
        <bootstrap.Modal.Footer>
          <bootstrap.Button onClick={handleClose}>Close</bootstrap.Button>
        </bootstrap.Modal.Footer>
      </bootstrap.Modal>
    </div>
  )
}


function Accounting() {
  return (
    <bootstrap.Card.Body>
      <List bulleted>
        <List.Item>Bookkeeping Services</List.Item>
        <List.Item>Bank Reconciliations</List.Item>
        <List.Item>Payroll</List.Item>
        <List.Item>Accounting Software Advice</List.Item>
      </List>
      <bootstrap.Container>
        <bootstrap.Row>
          <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true} >
            <a href="https://www.myob.com/au">
              <img src={myob} style={{ "width": "50%", "margin-left": "25%" }} />
            </a>
          </bootstrap.Col>
          <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true} >
            <a href="https://www.xero.com/au/">
              <img src={xero} style={{ "width": "60%", "margin-left": "25%" }} />
            </a>
          </bootstrap.Col>
        </bootstrap.Row>
      </bootstrap.Container>
    </bootstrap.Card.Body>
  )
}


function AccountingModal() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <bootstrap.Button size="lg" variant="link" onClick={handleShow}><Icon name="balance sales" />Accounting</bootstrap.Button>
      <bootstrap.Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <bootstrap.Modal.Header>
          <bootstrap.Modal.Title>Accounting</bootstrap.Modal.Title>
        </bootstrap.Modal.Header>
        <bootstrap.Modal.Body>
          <Accounting />
        </bootstrap.Modal.Body>
        <bootstrap.Modal.Footer>
          <bootstrap.Button onClick={handleClose}>Close</bootstrap.Button>
        </bootstrap.Modal.Footer>
      </bootstrap.Modal>
    </div>
  )
}


function BusinessRegistrations() {
  return (
    <bootstrap.Card.Body>
      <List bulleted>
        <List.Item>Registration of various business entities such as; Sole Traders, Partnerships, Trusts and Companies</List.Item>
        <List.Item>Australian Business Number (ABN)</List.Item>
        <List.Item>Pay As You Go (PAYG)</List.Item>
        <List.Item>Goods and Services Tax (GST)</List.Item>
      </List>
    </bootstrap.Card.Body>
  )
}


function BusinessRegistrationsModal() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <bootstrap.Button size="lg" variant="link" onClick={handleShow}><Icon name="thumbtack" />Business Registrations</bootstrap.Button>
      <bootstrap.Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <bootstrap.Modal.Header>
          <bootstrap.Modal.Title>Business Registrations</bootstrap.Modal.Title>
        </bootstrap.Modal.Header>
        <bootstrap.Modal.Body>
          <BusinessRegistrations />
        </bootstrap.Modal.Body>
        <bootstrap.Modal.Footer>
          <bootstrap.Button onClick={handleClose}>Close</bootstrap.Button>
        </bootstrap.Modal.Footer>
      </bootstrap.Modal>
    </div>
  )
}


function FinancialStatements() {
  return (
    <bootstrap.Card.Body>
      <List bulleted>
        <List.Item>Preparation of Financial statements for Companies, Trusts, Partnerships and Self Managed Super Funds (SMSFs)</List.Item>
      </List>
    </bootstrap.Card.Body>
  )
}


function FinancialStatementsModal() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <bootstrap.Button size="lg" variant="link" onClick={handleShow}><Icon name="folder open" />Financial Statements</bootstrap.Button>
      <bootstrap.Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <bootstrap.Modal.Header>
          <bootstrap.Modal.Title>Financial Statements</bootstrap.Modal.Title>
        </bootstrap.Modal.Header>
        <bootstrap.Modal.Body>
          <FinancialStatements />
        </bootstrap.Modal.Body>
        <bootstrap.Modal.Footer>
          <bootstrap.Button onClick={handleClose}>Close</bootstrap.Button>
        </bootstrap.Modal.Footer>
      </bootstrap.Modal>
    </div>
  )
}


function Welcome() {
  return (
    <Card fluid >
      <Card.Content>
        <Card.Meta className="welcome-text">
          <h5>CUE Accounting makes accounting, bookkeeping and reporting easy for you. Our core focus is to add value to your business.</h5>
          <h5>We provide a professional, personalised service and build collaborative working relationships with you.</h5>
        </Card.Meta>
      </Card.Content>
    </Card>
  )
}


const ResponsiveContainer = () => {
  return (
    <div>

      <CUEMenu />
      <bootstrap.Container>
        <Parallax bgImage={bg} bgImageAlt="welcome pic" strength={300} blur={0}>
          <div id="menu-mobile" style={{ height: 200 }}>
          </div>
        </Parallax>

        <Segment id="welcome" raised textAlign="center" >
          <Welcome />
        </Segment>

        <Segment raised>
          <Header id="services" as="h1" textAlign='center'>
            What we offer
          </Header>

          <bootstrap.Container fluid>
            <bootstrap.Row>
              <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true}>
                <Header as="h2" icon>
                  <TaxationModal />
                </Header>
              </bootstrap.Col>
              <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true}>
                <Header as="h2" icon>
                  <AccountingModal />
                </Header>
              </bootstrap.Col>
              <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true}>
                <Header as="h2" icon>
                  <FinancialStatementsModal />
                </Header>
              </bootstrap.Col>
              <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true}>
                <Header as="h2" icon>
                  <BusinessRegistrationsModal />
                </Header>
              </bootstrap.Col>
            </bootstrap.Row>
          </bootstrap.Container>
        </Segment>

        <bootstrap.Row>
          <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true}>
            <Segment id="about" raised>
              <Header as="h1" textAlign='center'>About</Header>
              <AboutClare />
            </Segment>
          </bootstrap.Col>
        </bootstrap.Row>

        <bootstrap.Row>
          <bootstrap.Col xs={12} md={6} lg={true} xl={true} xxl={true}>
            <Segment id="reviews" raised>
              <Header as="h1" textAlign='center'>Our Happy Clients</Header>
              <Reviews />
            </Segment>
          </bootstrap.Col>
        </bootstrap.Row>

        <Segment raised textAlign="center" >
          <Banner />
        </Segment>

        <Segment raised textAlign="center" >
          <Header id="contact" as="h1" textAlign='center'>Contact Us</Header>
          <ContactUs />
        </Segment>

      </bootstrap.Container>

    </div >
  )
}


function App() {
  return (
    ResponsiveContainer()
  );
}

export default App;
